<template>
  <div class="group-providers">
    <atomic-cat-heading
      class="group-providers__title"
      :icon="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.providers.icon')"
    >
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.providers.label') }}
    </atomic-cat-heading>

    <div class="group-providers__action">
      <span class="show-all" @click="openProviders">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
      </span>

      <button-arrows
        v-if="showArrowButtons"
        :prevDisabled="prevDisabled"
        :nextDisabled="nextDisabled"
        @click-action="clickAction"
      />
    </div>

    <div v-if="filteredProviders?.length" ref="scrollContainer" class="items" @scroll="scrollHandler">
      <card-providers v-for="provider in filteredProviders" :key="provider.id" :providerData="provider" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { scrollBodyToTop } from '@skeleton/utils/scrollUtils';

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { getContent, localizePath } = useProjectMethods();
  const gameProvidersStore = useGameProvidersStore();
  await useLazyAsyncData(() => gameProvidersStore.getProviders(), { server: false });

  const scrollContainer = ref();
  const prevDisabled = ref(true);
  const nextDisabled = ref(false);
  const showArrowButtons = ref(true);
  const OFFSET_MARGIN = 20;

  const filteredProviders = computed(() => {
    const items = gameProvidersStore.getFilteredProviders;

    if (!items) {
      return [];
    }

    return isMobile.value ? items.slice(0, 6) || [] : items || [];
  });

  const openProviders = async () => {
    scrollBodyToTop();
    await navigateTo({
      path: localizePath(`/games/providers`),
    });
  };

  const scrollHandler = async (): Promise<void> => {
    if (!scrollContainer.value || !filteredProviders.value?.length) return;
    await nextTick();
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    if (offsetWidth === 0 || scrollWidth === 0) return;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value = scrollWidth <= scrollLeft + offsetWidth + OFFSET_MARGIN;
  };

  onMounted(async () => {
    await nextTick();
    scrollHandler();
    showArrowButtons.value = !prevDisabled.value || !nextDisabled.value;
  });

  watch(
    filteredProviders,
    async () => {
      await nextTick();
      scrollHandler();
    },
    { immediate: true }
  );

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };
</script>

<style src="~/assets/styles/components/group/providers.scss" lang="scss" />
